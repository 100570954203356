<template>
  <div>
    <div v-for="error_message in errors" v-if="errors.length > 0">
      <CAlert color="danger" closeButton>{{error_message}}</CAlert>
    </div>

    <section v-if="allowed">
      <h4>Operación</h4>
      <b-row>
        <b-col lg="4">
          <CCard @click="goto('calendar')">          
              <CCardBody>
                <h3>Agenda</h3>
              </CCardBody>
          </CCard>
        </b-col>
      </b-row>
      <h4>KPI's</h4>
      <b-row>
        <b-col lg="4">
          <CCard @click="goto('execution')">          
              <CCardBody>
                <h3>Ejecución</h3>
              </CCardBody>
          </CCard>
        </b-col>
        <b-col lg="4">
          <CCard @click="goto('clients')">          
              <CCardBody>
                <h3>Clientes</h3>
              </CCardBody>
          </CCard>
        </b-col>
        <b-col lg="4">
          <CCard @click="goto('clients-type')">          
              <CCardBody>
                <h3>Actividad de clientes</h3>
              </CCardBody>
          </CCard>
        </b-col>
        <b-col lg="4">
          <CCard @click="goto('providers-distance')">          
              <CCardBody>
                <h3>Paseadores distancia</h3>                
              </CCardBody>
          </CCard>
        </b-col>
      </b-row>

    </section>

    <section v-if="allowed && owner">
      <h4>Owner</h4>
      <b-row>
        <b-col lg="4">
          <CCard @click="goto('orderFixCountLeft')">          
              <CCardBody>
                <h3>Pedido act. restantes</h3>
              </CCardBody>
          </CCard>
        </b-col>
      </b-row>
    </section>

  </div>
</template>

<script>
export default {
  name: "AdminDashboard",
  data () {
    return {
      errors: [],
      allowed: false,
      owner: false,
    }
  },
  created() {
    this.userProfile = JSON.parse(this.$store.getters.userProfile); 
    if(this.userProfile.acceso.id == 1) this.owner = true
    if (this.userProfile.status.id == 2) {
      this.allowed = true      
    } else {
      this.errors.push("No tienes acceso a este módulo");
    }
  },
  methods: {
    goto(to) {
      if(to == 'execution') {
        this.$router.push({name: 'admin-execution'})
      } else if(to == 'clients') {
        this.$router.push({name: 'admin-clients'})
      } else if(to == 'clients-type') {
        this.$router.push({name: 'admin-clients-activity'})      
      } else if(to == 'providers-distance') {
        this.$router.push({name: 'admin-providers-distance'})
      } else if(to == 'calendar') {
        this.$router.push({name: 'admin-calendar'})
      } else if(to == 'orderFixCountLeft') {
        this.$router.push({name: 'admin-owner-fixcountleft'})
      }
    }
  }
}
</script>